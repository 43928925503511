import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  IconButton
} from '@mui/material';

import { Delete, Edit } from '@mui/icons-material';

// import { formatDate } from '../../../../resources/functions';
import { RewardBonuses } from '../../../../redux/slices/rewardBonusesSlice';

interface Props {
  items: RewardBonuses[];
  openDialog?: (partnerCompany: RewardBonuses) => void;
  onDelete?: (_id: string) => void;
}

const cellStyles = {
  color: 'white'
};

const RewardBonusesTable = ({ items, openDialog, onDelete }: Props) => {
  return (
    <TableContainer sx={{ mt: 3 }} component={Paper}>
      <Table>
        <TableHead sx={{ bgcolor: '#2FCB75' }}>
          <TableRow>
            <TableCell sx={cellStyles}>Фонове Зображення</TableCell>
            <TableCell sx={cellStyles}>Назва</TableCell>
            <TableCell sx={cellStyles}>Опис</TableCell>
            <TableCell sx={cellStyles}>Ліміт активацій</TableCell>
            <TableCell sx={cellStyles}>Кількість активацій</TableCell>
            <TableCell sx={cellStyles}>Активовано</TableCell>
            {/* <TableCell sx={cellStyles}>Дата створення</TableCell>
            <TableCell sx={cellStyles}>Дата оновлення</TableCell> */}
            <TableCell sx={cellStyles}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map(partnerCompany => {
            return (
              <TableRow hover key={partnerCompany._id}>
                <TableCell>
                  {partnerCompany.bgPhoto?.startsWith('uploads/') ? (
                    <img
                      alt='icon-img'
                      style={{ maxWidth: 150 }}
                      src={`${process.env.REACT_APP_API_URL}/public/${partnerCompany.bgPhoto}`}
                    />
                  ) : (
                    'Немає логотипу'
                  )}
                </TableCell>
                <TableCell>{partnerCompany.name}</TableCell>
                <TableCell>{partnerCompany.description}</TableCell>
                <TableCell>{partnerCompany.activateLimit ?? 0}</TableCell>
                <TableCell>{partnerCompany.activateCount ?? 0}</TableCell>
                <TableCell>
                  {partnerCompany.enabled ? 'Активовано' : 'Де активовано'}
                </TableCell>
                {/* <TableCell>{formatDate(partnerCompany.createdAt)}</TableCell>
                <TableCell>{formatDate(partnerCompany.updatedAt)}</TableCell> */}
                <TableCell>
                  {/* {isSuperAdmin ? ( */}
                  <>
                    <IconButton
                      size='small'
                      onClick={() => {
                        openDialog?.(partnerCompany);
                      }}
                    >
                      <Edit />
                    </IconButton>

                    <IconButton
                      size='small'
                      onClick={() => onDelete?.(partnerCompany._id)}
                    >
                      <Delete fontSize='small' />
                    </IconButton>
                  </>
                  {/* ) : null} */}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default RewardBonusesTable;
