import React, { useMemo } from 'react';
import { FormControl } from '@mui/material';
import { InputLabel } from '@material-ui/core';
import UploadButton from '../UploadButton';
import { InputChangeEvent } from '../../resources/utils';

interface IFilePickerInputProps {
  label?: string;
  filePickerLabel?: string;
  value?: File;
  onChange: (value: File) => void;
  disable?: boolean;
  previewStyle?: React.CSSProperties;
  previewUrl?: string;
}

const FilePickerInput = ({
  label,
  filePickerLabel,
  disable,
  value,
  onChange,
  previewStyle,
  previewUrl
}: IFilePickerInputProps) => {
  const imageSrc = value ? URL.createObjectURL(value as File) : previewUrl;

  const filePiclerButtonLabel = useMemo(() => {
    if (value) {
      return filePickerLabel ?? 'Змінити файл';
    }
    return filePickerLabel ?? 'Вибрати файл';
  }, [filePickerLabel, value]);

  return (
    <FormControl fullWidth margin={'dense'}>
      {label ? <InputLabel>{label}</InputLabel> : null}

      {imageSrc ? (
        <img
          style={{
            marginTop: 30,
            marginBottom: 30,
            width: '100%',
            height: 'auto',
            ...previewStyle
          }}
          src={imageSrc}
          alt='Preview'
        />
      ) : null}

      <UploadButton
        loading={disable}
        buttonProps={{
          color: 'primary',
          variant: 'text'
        }}
        onChange={async (e: InputChangeEvent) => {
          const { files } = e.target;

          if (files) {
            const file = files[0];

            console.log('onChange file', file);
            onChange(file);
          }
        }}
      >
        {filePiclerButtonLabel}
      </UploadButton>
    </FormControl>
  );
};

export default FilePickerInput;
