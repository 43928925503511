import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useMemo,
  useEffect
} from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormGroup,
  FormControlLabel,
  Switch
} from '@mui/material';
import { Formik, Form, FormikHelpers } from 'formik';
import * as yup from 'yup';
import { useSelector } from 'react-redux';
import { SelectOption } from '../../../../components/Select/interface';
import Select from '../../../../components/Select';
import { RewardBonusesData } from '../../../../redux/slices/rewardBonusesSlice';
import FilePickerInput from '../../../../components/FilePickerInput';
import {
  RewardPartnerCompany,
  selectRewardPartnerCompany
} from '../../../../redux/slices/rewardPartnerCompanySlice';

interface RewardBonusesModalProps {
  onSave: (
    values: RewardBonusesData,
    bgPhoto?: string | File,
    isEditMode?: boolean
  ) => void;
}

const defaultInitialData: RewardBonusesData = {
  partnerCompany: '',
  name: '',
  description: '',
  bgPhoto: '',
  instagram: '',
  website: '',
  location: {
    lat: 0,
    lon: 0
  },
  activateLimit: 0,
  bonusPointsPrice: 0,
  enabled: false
};

export interface RewardBonusesModalHandles {
  openAddModal: () => void;
  openEditModal: (initialData: RewardBonusesData) => void;
}

const validationSchema = yup.object({
  partnerCompany: yup.string().required('Партнерська компанія є обов’язковою'),
  name: yup.string().required('Назва є обов’язковою'),
  description: yup.string().required('Опис є обов’язковим'),
  instagram: yup
    .string()
    .url('Некоректне посилання на Instagram')
    .notRequired(),
  website: yup.string().url('Некоректне посилання на сайт').notRequired(),
  location: yup.object({
    lat: yup
      .number()
      .required('Широта є обов’язковою')
      .min(-90, 'Широта має бути від -90 до 90')
      .max(90, 'Широта має бути від -90 до 90'),
    lon: yup
      .number()
      .required('Довгота є обов’язковою')
      .min(-180, 'Довгота має бути від -180 до 180')
      .max(180, 'Довгота має бути від -180 до 180')
  }),
  activateLimit: yup
    .number()
    .required('Ліміт активацій є обов’язковим')
    .min(0, 'Ліміт активацій не може бути від’ємним'),
  bonusPointsPrice: yup
    .number()
    .required('Ціна бонусних балів є обов’язковою')
    .min(0, 'Ціна бонусних балів не може бути від’ємною'),
  enabled: yup.boolean().notRequired()
});

const RewardBonusesModal = forwardRef<
  RewardBonusesModalHandles,
  RewardBonusesModalProps
>(({ onSave }, ref) => {
  const { items: rewardPartnerCompanies } = useSelector(
    selectRewardPartnerCompany
  );

  const [open, setOpen] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [initialData, setInitialData] =
    useState<RewardBonusesData>(defaultInitialData);

  const [bgPhoto, setBgPhoto] = useState<File | undefined>();

  const rewardPartnerCompaniesOptions = useMemo<SelectOption[]>(
    () => [
      { value: '', label: '-' },
      ...rewardPartnerCompanies.map(rewardPartnerCompany => ({
        label: rewardPartnerCompany.name,
        value: rewardPartnerCompany._id
      }))
    ],
    [rewardPartnerCompanies]
  );

  useEffect(() => {
    if (!open) {
      setInitialData(defaultInitialData);
      setBgPhoto(undefined);
    }
  }, [open]);

  useImperativeHandle(ref, () => ({
    openAddModal() {
      setIsEditMode(false);
      setInitialData(defaultInitialData);
      setOpen(true);
    },
    openEditModal(data: RewardBonusesData) {
      setIsEditMode(true);
      const bonusData = { ...data };
      bonusData.partnerCompany = (
        data.partnerCompany as RewardPartnerCompany
      )?._id;
      setInitialData(bonusData);
      setOpen(true);
    }
  }));

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (
    values: RewardBonusesData,
    helpers: FormikHelpers<RewardBonusesData>
  ) => {
    onSave(values, bgPhoto, isEditMode);
    setOpen(false);
    helpers.setSubmitting(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth='sm'>
      <DialogTitle>
        {isEditMode ? 'Редагувати бонус' : 'Додати бонус'}
      </DialogTitle>
      <Formik
        initialValues={initialData}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          handleChange,
          handleBlur,
          setFieldValue,
          isSubmitting,
          touched,
          errors
        }) => (
          <Form>
            <DialogContent>
              <Select
                fullWidth
                label='Партнерська компанія'
                options={rewardPartnerCompaniesOptions}
                value={values.partnerCompany as string}
                onChange={value => setFieldValue('partnerCompany', value)}
                error={Boolean(touched.partnerCompany && errors.partnerCompany)}
                helperText={
                  touched.partnerCompany && errors.partnerCompany
                    ? errors.partnerCompany
                    : undefined
                }
              />
              <TextField
                margin='dense'
                label='Назва'
                name='name'
                fullWidth
                variant='standard'
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.name && errors.name)}
                helperText={errors.name}
              />
              <TextField
                margin='dense'
                label='Опис'
                name='description'
                fullWidth
                variant='standard'
                value={values.description}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.description && errors.description)}
                helperText={errors.description}
              />
              <TextField
                margin='dense'
                label='Instagram'
                name='instagram'
                fullWidth
                variant='standard'
                value={values.instagram}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.instagram && errors.instagram)}
                helperText={errors.instagram}
              />
              <TextField
                margin='dense'
                label='Сайт'
                name='website'
                fullWidth
                variant='standard'
                value={values.website}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.website && errors.website)}
                helperText={errors.website}
              />
              <TextField
                margin='dense'
                label='Ціна бонусних балів'
                type='number'
                name='bonusPointsPrice'
                fullWidth
                variant='standard'
                value={values.bonusPointsPrice}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(
                  touched.bonusPointsPrice && errors.bonusPointsPrice
                )}
                helperText={errors.bonusPointsPrice}
              />
              <TextField
                margin='dense'
                label='Ліміт активацій'
                type='number'
                name='activateLimit'
                fullWidth
                variant='standard'
                value={values.activateLimit}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.activateLimit && errors.activateLimit)}
                helperText={errors.activateLimit}
              />
              <TextField
                margin='dense'
                label='Широта'
                type='number'
                name='lat'
                fullWidth
                variant='standard'
                value={values?.location?.lat}
                onChange={e =>
                  setFieldValue('location.lat', Number(e.target.value))
                }
                onBlur={handleBlur}
              />
              <TextField
                margin='dense'
                label='Довгота'
                type='number'
                name='lon'
                fullWidth
                variant='standard'
                value={values?.location?.lon}
                onChange={e =>
                  setFieldValue('location.lon', Number(e.target.value))
                }
                onBlur={handleBlur}
              />
              <FilePickerInput
                label='Фонове зображення'
                value={bgPhoto || undefined}
                onChange={(file: File) => {
                  setBgPhoto(file);
                }}
              />
              {isEditMode ? (
                <FormGroup sx={{ marginLeft: 'auto' }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={values.enabled}
                        onChange={event =>
                          setFieldValue('enabled', event.target.checked)
                        }
                      />
                    }
                    label='Активовано'
                  />
                </FormGroup>
              ) : null}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Скасувати</Button>
              <Button type='submit' color='primary' disabled={isSubmitting}>
                Зберегти
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
});

export default RewardBonusesModal;
