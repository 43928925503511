import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  // useMemo,
  useEffect
} from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField
} from '@mui/material';
import { Formik, Form, FormikHelpers } from 'formik';
import * as yup from 'yup';
// import { useSelector } from 'react-redux';
// import { selectCities } from '../../../../redux/slices/citiesSlice';
// import { SelectOption } from '../../../../components/Select/interface';
// import Select from '../../../../components/Select';
import FilePickerInput from '../../../../components/FilePickerInput';

export interface FormValues {
  // city: string;
  name: string;
  logo?: string;
}

interface RewardsPartnersModalProps {
  onSave: (
    values: FormValues,
    logo?: string | File,
    isEditMode?: boolean
  ) => void;
}

export interface RewardsPartnersModalHandles {
  openAddModal: () => void;
  openEditModal: (initialData: FormValues) => void;
}

const validationSchema = yup.object({
  // city: yup.string().required('Місто є обов’язковим'),
  name: yup.string().required('Назва є обов’язковою')
});

const RewardsPartnersModal = forwardRef<
  RewardsPartnersModalHandles,
  RewardsPartnersModalProps
>(({ onSave }, ref) => {
  // const { items: cities } = useSelector(selectCities);

  const [open, setOpen] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [initialData, setInitialData] = useState<FormValues>({
    // city: '',
    name: ''
  });

  const [logo, setLogo] = useState<File | undefined>();

  useEffect(() => {
    if (!open) {
      setInitialData({ name: '' });
      setLogo(undefined);
    }
  }, [open]);

  // const citiesOptions = useMemo<SelectOption[]>(
  //   () => [
  //     {
  //       value: '',
  //       label: '-'
  //     },
  //     ...cities.map(city => ({
  //       label: city.cityName,
  //       value: city._id
  //     }))
  //   ],
  //   [cities]
  // );

  useImperativeHandle(ref, () => ({
    openAddModal() {
      setIsEditMode(false);
      // setInitialData({ city: '', name: '' });
      setInitialData({ name: '' });
      setOpen(true);
    },
    openEditModal(data: FormValues) {
      setIsEditMode(true);
      setInitialData(data);
      setOpen(true);
    }
  }));

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (
    values: FormValues,
    helpers: FormikHelpers<FormValues>
  ) => {
    onSave(values, logo, isEditMode);
    setOpen(false);
    helpers.setSubmitting(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth='sm'>
      <DialogTitle>
        {isEditMode ? 'Редагувати компанію' : 'Додати компанію'}
      </DialogTitle>
      <Formik
        initialValues={initialData}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          handleChange,
          handleBlur,
          setFieldValue,
          isSubmitting,
          touched,
          errors
        }) => (
          <Form>
            <DialogContent>
              {/* <Select
                fullWidth
                label='Місто'
                options={citiesOptions}
                onChange={value => setFieldValue('city', value)}
                value={values.city}
              /> */}
              <TextField
                margin='dense'
                label='Назва'
                name='name'
                fullWidth
                variant='standard'
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name ? errors.name : ''}
              />
              {/* <FilePickerInput
                label={'Логотип'}
                filePickerLabel={companyLogoSelectButton}
                onChange={setLogo}
              /> */}
              <FilePickerInput
                label='Логотип'
                value={logo || undefined}
                onChange={(file: File) => {
                  setLogo(file);
                }}
                // previewUrl='https://example.com/image.jpg'
                previewStyle={{
                  borderRadius: '50%',
                  objectFit: 'cover',
                  width: 150,
                  height: 150
                }}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Скасувати</Button>
              <Button type='submit' color='primary' disabled={isSubmitting}>
                Зберегти
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
});

export default RewardsPartnersModal;
