import React from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  IconButton,
  Avatar,
  Tooltip
} from '@mui/material';
import { Edit } from '@mui/icons-material';
// import { formatDate } from '../../../../resources/functions';
import { FortuneWheelConfigData } from '../../../../redux/slices/fortuneWheelConfigSlice';

interface FortuneWheelConfigTableProps {
  config: FortuneWheelConfigData;
  onEdit?: (config: FortuneWheelConfigData) => void;
}

const FortuneWheelConfigTable: React.FC<FortuneWheelConfigTableProps> = ({
  config,
  onEdit
}) => {
  const renderPartnerCompany = () => {
    if (config.partnerCompany && typeof config.partnerCompany === 'object') {
      const partner = config.partnerCompany as {
        _id: string;
        name: string;
        logo?: string;
      };
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {partner.logo && partner.logo.startsWith('uploads/') && (
            <Avatar
              src={`${process.env.REACT_APP_API_URL}/public/${partner.logo}`}
              alt={partner.name}
              sx={{ width: 24, height: 24, mr: 1 }}
            />
          )}
          <span>{partner.name}</span>
        </div>
      );
    }
    return <span>{config.partnerCompany}</span>;
  };

  return (
    <TableContainer sx={{ mt: 3 }} component={Paper}>
      <Table>
        <TableHead sx={{ bgcolor: '#2FCB75' }}>
          <TableRow>
            {/* <TableCell sx={{ color: 'white' }}>Місто</TableCell> */}
            <TableCell sx={{ color: 'white' }}>Назва</TableCell>
            <TableCell sx={{ color: 'white' }}>Ціна бонусних балів</TableCell>
            <TableCell sx={{ color: 'white' }}>Активовано</TableCell>
            <TableCell sx={{ color: 'white' }}>Партнерська компанія</TableCell>
            {/* <TableCell sx={{ color: 'white' }}>Дата створення</TableCell>
            <TableCell sx={{ color: 'white' }}>Дата оновлення</TableCell> */}
            <TableCell sx={{ color: 'white' }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow hover>
            {/* <TableCell>{config.city}</TableCell> */}
            <TableCell>{config.name}</TableCell>
            <TableCell>{config.bonusPointsPrice}</TableCell>
            <TableCell>{config.enabled ? 'Так' : 'Ні'}</TableCell>
            <TableCell>{renderPartnerCompany()}</TableCell>
            {/* <TableCell>{config.createdAt ? formatDate(config.createdAt) : '-'}</TableCell>
            <TableCell>{config.updatedAt ? formatDate(config.updatedAt) : '-'}</TableCell> */}
            <TableCell>
              <Tooltip title='Редагувати'>
                <IconButton
                  size='small'
                  onClick={() => onEdit && onEdit(config)}
                >
                  <Edit />
                </IconButton>
              </Tooltip>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FortuneWheelConfigTable;
