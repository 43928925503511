import { useDispatch, useSelector } from 'react-redux';
import RewardsPartnersModal, {
  FormValues,
  RewardsPartnersModalHandles
} from '../RewardsPartnersModal';
import { RefObject, useEffect } from 'react';
import {
  createRewardPartnerCompanyAsync,
  deleteRewardPartnerCompanyAsync,
  getRewardPartnerCompaniesAsync,
  RewardPartnerCompanyData,
  selectRewardPartnerCompany
} from '../../../../redux/slices/rewardPartnerCompanySlice';
import TableNoItems from '../../TableNoItems';
import RewardsPartnersTable from './RewardsPartnersTable';

interface IRewardsPartnersProps {
  cityId: string;
  modalRef: RefObject<RewardsPartnersModalHandles>;
  onSave: (
    values: FormValues,
    logo?: string | File,
    isEditMode?: boolean
  ) => void;
}

const RewardsPartners = ({
  modalRef,
  cityId,
  onSave
}: IRewardsPartnersProps) => {
  const dispatch = useDispatch();
  const { items: rewardPartnerCompanies, loading } = useSelector(
    selectRewardPartnerCompany
  );

  useEffect(() => {
    dispatch(getRewardPartnerCompaniesAsync());
  }, []);

  console.log('-=-=- rewardPartnerCompanies', rewardPartnerCompanies);

  const handleSave = (
    data: RewardPartnerCompanyData,
    logo?: string | File,
    isEditMode?: boolean
  ) => {
    console.log('-=-=- handleSave', data);
    dispatch(
      createRewardPartnerCompanyAsync({ ...data, city: cityId }, logo, () => {
        onSave(data, logo, isEditMode);
      })
    );
    // createRewardPartnerCompanyAsync
    // onSave(data, isEditMode);
  };

  return (
    <>
      {!loading && !rewardPartnerCompanies.length ? <TableNoItems /> : null}

      {!loading && rewardPartnerCompanies.length ? (
        <RewardsPartnersTable
          items={rewardPartnerCompanies}
          // openDialog={(partnerCompany?: RewardPartnerCompany) => true}
          onDelete={(_id: string) =>
            dispatch(deleteRewardPartnerCompanyAsync(_id))
          }
        />
      ) : null}

      <RewardsPartnersModal ref={modalRef} onSave={handleSave} />
    </>
  );
};

export default RewardsPartners;
