import { createSlice } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../store';
import axios from '../../resources/axios';
import { handleDefaultErrors } from '../../resources/functions';
import { RewardPartnerCompany } from './rewardPartnerCompanySlice';

export interface FortuneWheelBonus {
  type: 'money' | 'subscriptionPromocode';
  amount?: number;
  subscriptionPlan?: string;
  nthSpin: number;
  bonusIndex?: number;
}

export interface FortuneWheelConfigData {
  _id?: string;
  city: string;
  enabled: boolean;
  bonusPointsPrice: number;
  name: string;
  bgPhoto?: string;
  partnerCompany: string | RewardPartnerCompany;
  description: string;
  bonuses: FortuneWheelBonus[];
}

export interface FortuneWheelSettings {
  MIN_CONTRACTS_PERIOD_HOURS: number;
  MIN_CONTRACTS_REQUIRED: number;
  MIN_EACH_CONTRACT_DURATION_SECONDS: number;
}

export interface FortuneWheelConfigResponse {
  config: FortuneWheelConfigData;
  settings: FortuneWheelSettings;
}

interface FortuneWheelConfigState {
  config: FortuneWheelConfigData | null;
  settings: FortuneWheelSettings | null;
  loading: boolean;
}

const initialState: FortuneWheelConfigState = {
  config: null,
  settings: null,
  loading: false
};

const fortuneWheelConfigSlice = createSlice({
  name: 'fortuneWheelConfig',
  initialState,
  reducers: {
    setFortuneWheelConfigLoading: (state, action) => {
      state.loading = action.payload;
    },
    setFortuneWheelConfig: (state, action) => {
      state.config = action.payload.config;
      state.settings = action.payload.settings;
    }
  }
});

export const { setFortuneWheelConfigLoading, setFortuneWheelConfig } =
  fortuneWheelConfigSlice.actions;

export const refreshFortuneWheelConfigAsync =
  (): AppThunk => async dispatch => {
    try {
      const response = await axios.get('/reward/fortune_wheel/admin');
      dispatch(setFortuneWheelConfig(response.data.data));
    } catch (error) {
      handleDefaultErrors(error);
    }
  };

export const createFortuneWheelConfigAsync =
  (
    data: FortuneWheelConfigData,
    file?: string | File,
    success?: () => void
  ): AppThunk =>
  async dispatch => {
    try {
      let configData = { ...data };
      if (file instanceof File) {
        const formData = new FormData();
        formData.append('file', file);
        const response = await axios.post(`/files/persistent`, formData);
        configData.bgPhoto = response.data.path;
      }
      await axios.post('/reward/fortune_wheel/config', configData);
      if (success) {
        success();
      }
      dispatch(refreshFortuneWheelConfigAsync());
    } catch (error) {
      handleDefaultErrors(error);
    }
  };

export const selectFortuneWheelConfig = (state: RootState) =>
  state.fortuneWheelConfig;

export default fortuneWheelConfigSlice.reducer;
