import React, { useEffect, RefObject } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TableNoItems from '../../TableNoItems';
import FortuneWheelConfigTable from './FortuneWheelConfigTable';
import {
  refreshFortuneWheelConfigAsync,
  selectFortuneWheelConfig,
  FortuneWheelConfigData,
  createFortuneWheelConfigAsync
} from '../../../../redux/slices/fortuneWheelConfigSlice';
import FortuneWheelConfigModal, {
  FortuneWheelConfigModalHandles
} from '../FortuneWheelConfigModal';

interface IFortuneWheelConfigProps {
  cityId: string;
  modalRef: RefObject<FortuneWheelConfigModalHandles>;
  onSave: (
    data: FortuneWheelConfigData,
    file?: File,
    isEditMode?: boolean
  ) => void;
}

const FortuneWheelConfig: React.FC<IFortuneWheelConfigProps> = ({
  cityId,
  modalRef,
  onSave
}) => {
  const dispatch = useDispatch();
  const { config, loading } = useSelector(selectFortuneWheelConfig);

  useEffect(() => {
    dispatch(refreshFortuneWheelConfigAsync());
  }, [dispatch]);

  const handleEdit = (config: FortuneWheelConfigData) => {
    modalRef.current?.openModalWithData(config);
  };

  const handleSave = (
    data: FortuneWheelConfigData,
    logo?: string | File,
    isEditMode?: boolean
  ) => {
    console.log('-=-=- handleSave', data);
    dispatch(
      createFortuneWheelConfigAsync({ ...data, city: cityId }, logo, () => {
        onSave(data, logo as File, isEditMode);
      })
    );
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {config ? (
        <FortuneWheelConfigTable config={config} onEdit={handleEdit} />
      ) : (
        <TableNoItems text='Немає конфігурації Fortune Wheel' />
      )}
      <FortuneWheelConfigModal ref={modalRef} onSave={handleSave} />
    </>
  );
};

export default FortuneWheelConfig;
