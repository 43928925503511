import { useEffect, useMemo, useRef } from 'react';
import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Title from '../../components/Title';
import RewardsPartners from './components/RewardsPartners';
import { RewardsPartnersModalHandles } from './components/RewardsPartnersModal';
import { RewardBonusesModalHandles } from './components/RewardBonusesModal';
import RewardBonuses from './components/RewardBonuses';
import { useDispatch, useSelector } from 'react-redux';
import {
  getRewardPartnerCompaniesAsync,
  selectRewardPartnerCompany
} from '../../redux/slices/rewardPartnerCompanySlice';
import { FortuneWheelConfigModalHandles } from './components/FortuneWheelConfigModal';
import FortuneWheelConfig from './components/FortuneWheelConfig';
import { selectCities } from '../../redux/slices/citiesSlice';
import { selectAdmins } from '../../redux/slices/adminsSlice';
import { selectFortuneWheelConfig } from '../../redux/slices/fortuneWheelConfigSlice';
import { getSubscriptionAsync } from '../../redux/slices/subscriptionSlice';

const PREFIX = 'RewardsPage';

const classes = {
  header: `${PREFIX}-header`,
  addButton: `${PREFIX}-addButton`,
  tableContainer: `${PREFIX}-tableContainer`
};

const Root = styled('div')({
  [`& .${classes.header}`]: {
    display: 'flex',
    alignItems: 'center'
  },
  [`& .${classes.addButton}`]: {
    marginLeft: 30
  },
  [`& .${classes.tableContainer}`]: {
    marginTop: 40
  }
});

const RewardsPage = () => {
  const dispatch = useDispatch();

  const partnerCompaniesModalRef = useRef<RewardsPartnersModalHandles>(null);
  const partnerRewardsModalRef = useRef<RewardBonusesModalHandles>(null);
  const fortuneWheelConfigModalRef =
    useRef<FortuneWheelConfigModalHandles>(null);

  const { items: rewardPartnerCompanies } = useSelector(
    selectRewardPartnerCompany
  );

  const { config, loading } = useSelector(selectFortuneWheelConfig);

  const cities = useSelector(selectCities);
  const adminInfo = useSelector(selectAdmins);

  const cityId = useMemo(() => {
    const city = cities.items.find(item => item._id === adminInfo.currentCity);
    if (city) {
      return city._id;
    }
  }, [adminInfo.currentCity, cities.items]);

  useEffect(() => {
    dispatch(getRewardPartnerCompaniesAsync());
    dispatch(getSubscriptionAsync(cityId ?? ''));
  }, []);

  if (!cityId) {
    return null;
  }

  return (
    <Root>
      <div className={classes.header}>
        <Title>Винагороди</Title>

        {/* <Button
          className={classes.addButton}
          color='primary'
          startIcon={<AddIcon />}
          variant='text'
          onClick={toggleDialog(null)}
        >
          Додати
        </Button> */}
      </div>

      <div className={classes.tableContainer}>
        <div className={classes.header}>
          <Title variant='h6'>Партнерські компанії</Title>
          <Button
            className={classes.addButton}
            color='primary'
            startIcon={<AddIcon />}
            variant='text'
            onClick={() => partnerCompaniesModalRef.current?.openAddModal()}
          >
            Додати
          </Button>
        </div>
        <RewardsPartners
          modalRef={partnerCompaniesModalRef}
          cityId={cityId}
          onSave={() => true}
        />
      </div>

      {rewardPartnerCompanies?.length ? (
        <div className={classes.tableContainer}>
          <div className={classes.header}>
            <Title variant='h6'>Партнерські бонуси</Title>
            <Button
              className={classes.addButton}
              color='primary'
              startIcon={<AddIcon />}
              variant='text'
              onClick={() => partnerRewardsModalRef.current?.openAddModal()}
            >
              Додати
            </Button>
          </div>
          <RewardBonuses
            modalRef={partnerRewardsModalRef}
            cityId={cityId}
            onSave={() => true}
          />
        </div>
      ) : null}

      <div className={classes.tableContainer}>
        <div className={classes.header}>
          <Title variant='h6'>Конфігурація Fortune Wheel</Title>
          {!config && !loading ? (
            <Button
              className={classes.addButton}
              color='primary'
              startIcon={<AddIcon />}
              variant='text'
              onClick={() => fortuneWheelConfigModalRef.current?.openModal()}
            >
              Додати
            </Button>
          ) : null}
        </div>
        <FortuneWheelConfig
          cityId={cityId}
          modalRef={fortuneWheelConfigModalRef}
          onSave={() => true}
        />
      </div>
    </Root>
  );
};

export default RewardsPage;
