import { createSlice } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../store';
import axios from '../../resources/axios';
import { handleDefaultErrors } from '../../resources/functions';

export interface RewardPartnerCompanyData {
  city?: string;
  name: string;
  logo?: string;
}

export interface RewardPartnerCompany extends RewardPartnerCompanyData {
  _id: string;
  createdAt: string;
  updatedAt: string;
}

interface RewardPartnerCompanyState {
  items: any[];
  shouldUpdate: boolean;
  limit: number;
  page: number;
  total: number;
  loading: boolean;
}

const initialState: RewardPartnerCompanyState = {
  items: [],
  loading: false,
  shouldUpdate: true,
  limit: 20,
  page: 1,
  total: 1
};

const rewardPartnerCompanySlice = createSlice({
  name: 'rewardPartnerCompany',
  initialState,
  reducers: {
    // setNeedPlayAlert: (state, action: PayloadAction<boolean>) => {
    //   state.needPlayAlert = action.payload;
    // },
    // setNeedPlayStolenAlert: (state, action: PayloadAction<boolean>) => {
    //   state.needPlayStolenAlert = action.payload;
    // },
    // checkNotViewedNotices: (
    //   state,
    //   action: PayloadAction<{ notViewedCount: number }>
    // ) => {
    //   if (
    //     state.notViewedCount &&
    //     state.notViewedCount < action.payload.notViewedCount
    //   ) {
    //     state.needPlayAlert = true;
    //   }
    //   state.notViewedCount = action.payload.notViewedCount;
    // },
    // getNotices: (state, action) => {
    //   state.items = action.payload.adminNotices;
    //   state.total = action.payload.pagination.count;
    //   state.largeDistant = action.payload.largeDistant;
    //   state.outsideGreenZone = action.payload.outsideGreenZone;
    //   state.scooterDisconect = action.payload.scooterDisconect;
    //   state.usual = action.payload.usual;
    // },
    setRewardPartnerCompaniesLoading: (state, action) => {
      state.loading = action.payload.data;
    },
    getRewardPartnerCompanies: (state, action) => {
      state.items = action.payload.data;
    }
  }
});

export const {
  // setNeedPlayAlert,
  // checkNotViewedNotices,
  // getNotices,
  // getTypes,
  // setNeedPlayStolenAlert
  getRewardPartnerCompanies,
  setRewardPartnerCompaniesLoading
} = rewardPartnerCompanySlice.actions;

export const refreshRewardPartnerCompaniesAsync =
  (): AppThunk => async dispatch => {
    try {
      const response = await axios.get('/reward/partner_company');
      dispatch(getRewardPartnerCompanies(response.data));
    } catch (error) {
      handleDefaultErrors(error);
    }
  };

export const getRewardPartnerCompaniesAsync =
  (): AppThunk => async dispatch => {
    try {
      dispatch(setRewardPartnerCompaniesLoading(true));
      dispatch(refreshRewardPartnerCompaniesAsync());
      dispatch(setRewardPartnerCompaniesLoading(false));
    } catch (error) {
      handleDefaultErrors(error);
    }
  };

export const deleteRewardPartnerCompanyAsync =
  (id: string, success?: () => void): AppThunk =>
  async dispatch => {
    try {
      await axios.delete(`/reward/partner_company/${id}`);

      if (success) {
        success();
      }
      dispatch(refreshRewardPartnerCompaniesAsync());
    } catch (error) {
      handleDefaultErrors(error);
    }
  };

export const createRewardPartnerCompanyAsync =
  (
    data: RewardPartnerCompanyData,
    file?: string | File,
    success?: () => void
  ): AppThunk =>
  async dispatch => {
    try {
      let partnerCompanyData = data;
      if (file instanceof File) {
        const formData = new FormData();
        formData.append('file', file);
        const response = await axios.post(`/files/persistent`, formData);

        console.log('-=-=- response', response.data);
        partnerCompanyData.logo = response.data.path;
      }

      await axios.post(`/reward/partner_company`, data);

      if (success) {
        success();
      }

      dispatch(refreshRewardPartnerCompaniesAsync());
    } catch (error) {
      handleDefaultErrors(error);
    }
  };

export const selectRewardPartnerCompany = (state: RootState) =>
  state.rewardPartnerCompany;

export default rewardPartnerCompanySlice.reducer;
