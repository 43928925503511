import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useMemo
} from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Switch,
  FormGroup,
  FormControlLabel,
  Grid,
  IconButton
} from '@mui/material';
import { Formik, Form, FieldArray, FormikHelpers } from 'formik';
import * as yup from 'yup';
import FilePickerInput from '../../../../components/FilePickerInput';
import Select from '../../../../components/Select';
import { useSelector } from 'react-redux';
import { selectCities } from '../../../../redux/slices/citiesSlice';
import { SelectOption } from '../../../../components/Select/interface';
import {
  RewardPartnerCompany,
  selectRewardPartnerCompany
} from '../../../../redux/slices/rewardPartnerCompanySlice';
import { selectSubscriptions } from '../../../../redux/slices/subscriptionSlice';
import { Subscription } from '../../../../resources/types/balanceTypes';
import { Delete } from '@mui/icons-material';

interface BaseFortuneWheelBonus {
  type: 'money' | 'subscriptionPromocode';
  amount?: number;
  nthSpin: number;
  bonusIndex?: number;
}

export interface FortuneWheelBonus extends BaseFortuneWheelBonus {
  subscriptionPlan?: string;
}

export interface FortuneWheelBonusEdit extends BaseFortuneWheelBonus {
  subscriptionPlan?: string | Subscription;
}

interface BaseFortuneWheelConfigData<T extends BaseFortuneWheelBonus> {
  _id?: string;
  city: string;
  enabled: boolean;
  bonusPointsPrice: number;
  name: string;
  bgPhoto?: string;
  partnerCompany: string | RewardPartnerCompany;
  description: string;
  bonuses: T[];
}

export interface FortuneWheelConfigData
  extends BaseFortuneWheelConfigData<FortuneWheelBonus> {}

export interface FortuneWheelConfigDataEdit
  extends BaseFortuneWheelConfigData<FortuneWheelBonusEdit> {}

export interface FortuneWheelConfigModalHandles {
  openModal: () => void;
  openModalWithData: (data: FortuneWheelConfigData) => void;
}

interface FortuneWheelConfigModalProps {
  onSave: (
    data: FortuneWheelConfigData,
    file?: File,
    isEditMode?: boolean
  ) => void;
}

const defaultInitialValues: FortuneWheelConfigData = {
  city: '',
  enabled: true,
  bonusPointsPrice: 0,
  name: '',
  bgPhoto: '',
  partnerCompany: '',
  description: '',
  bonuses: [
    // Обов’язково має бути бонус з nthSpin: 1
    { type: 'money', amount: 0, nthSpin: 1 }
  ]
};

const validationSchema = yup.object({
  city: yup.string().required('Місто є обов’язковим'),
  bonusPointsPrice: yup
    .number()
    .min(0, 'Ціна має бути невід’ємною')
    .required('Ціна в бонусних баллах є обов’язковою'),
  name: yup.string().required('Назва є обов’язковою'),
  bgPhoto: yup.string().required('Фонове зображення є обов’язковим'),
  partnerCompany: yup.string().required('Партнерська компанія є обов’язковою'),
  description: yup.string().required('Опис є обов’язковим'),
  bonuses: yup
    .array()
    .of(
      yup.object({
        type: yup
          .string()
          .oneOf(['money', 'subscriptionPromocode'])
          .required('Тип бонусу є обов’язковим'),
        amount: yup.number().when(['type'], ([type], schema) => {
          return type === 'money'
            ? schema
                .min(0, 'Сума має бути невід’ємною')
                .required('Сума бонусу є обов’язковою')
            : schema.notRequired();
        }),
        subscriptionPlan: yup.string().when(['type'], ([type], schema) => {
          return type === 'subscriptionPromocode'
            ? schema.required('Виберіть план підписки')
            : schema.notRequired();
        }),
        nthSpin: yup
          .number()
          .min(1, 'nthSpin має бути не менше 1')
          .required('nthSpin є обов’язковим')
      })
    )
    .min(1, 'Необхідно задати хоча б один бонус')
    .test(
      'unique-nthSpin',
      'Значення nthSpin мають бути унікальними',
      bonuses => {
        if (!bonuses) return true;
        const spins = bonuses.map(b => b.nthSpin);
        return spins.length === new Set(spins).size;
      }
    )
    .test('default-bonus', 'Повинен бути бонус з nthSpin = 1', bonuses => {
      if (!bonuses) return false;
      return bonuses.some(b => b.nthSpin === 1);
    })
});

const FortuneWheelConfigModal = forwardRef<
  FortuneWheelConfigModalHandles,
  FortuneWheelConfigModalProps
>(({ onSave }, ref) => {
  const [open, setOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [initialValues, setInitialValues] =
    useState<FortuneWheelConfigData>(defaultInitialValues);
  const [bgPhotoFile, setBgPhotoFile] = useState<File | undefined>(undefined);

  const { items: cities } = useSelector(selectCities);
  const { items: rewardPartnerCompanies } = useSelector(
    selectRewardPartnerCompany
  );

  const { items: subscriptions } =
    useSelector(selectSubscriptions).subscriptions;

  const cityOptions = useMemo<SelectOption[]>(
    () => [
      {
        value: '',
        label: '-'
      },
      ...cities.map(city => ({
        label: city.cityName,
        value: city._id
      }))
    ],
    [cities]
  );

  const partnerCompaniesOptions = useMemo<SelectOption[]>(
    () => [
      {
        value: '',
        label: '-'
      },
      ...rewardPartnerCompanies.map(rewardPartnerCompany => ({
        label: rewardPartnerCompany.name,
        value: rewardPartnerCompany._id
      }))
    ],
    [rewardPartnerCompanies]
  );

  const subscriptionPlansOptions = useMemo<SelectOption[]>(
    () => [
      {
        value: '',
        label: '-'
      },
      ...subscriptions.map(subscription => ({
        label: subscription.name,
        value: subscription._id
      }))
    ],
    [subscriptions]
  );

  useImperativeHandle(ref, () => ({
    openModal: () => {
      setIsEditMode(false);
      setInitialValues(defaultInitialValues);
      setOpen(true);
    },
    openModalWithData: (data: FortuneWheelConfigDataEdit) => {
      setIsEditMode(true);
      const configurationData = {
        ...defaultInitialValues,
        ...data,
        partnerCompany: (data?.partnerCompany as RewardPartnerCompany)?._id,
        bonuses: data.bonuses.map(bonus => ({
          ...bonus,
          ...(bonus.type === 'subscriptionPromocode'
            ? {
                subscriptionPlan: (bonus.subscriptionPlan as Subscription)?._id
              }
            : {})
        }))
      };
      setInitialValues(configurationData as FortuneWheelConfigData);
      setOpen(true);
    }
  }));

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (
    values: FortuneWheelConfigData,
    helpers: FormikHelpers<FortuneWheelConfigData>
  ) => {
    onSave(values, bgPhotoFile, isEditMode);
    setOpen(false);
    helpers.setSubmitting(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth='md'>
      <DialogTitle>
        {isEditMode
          ? 'Редагувати конфігурацію Fortune Wheel'
          : 'Додати конфігурацію Fortune Wheel'}
      </DialogTitle>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({
          values,
          handleChange,
          handleBlur,
          setFieldValue,
          errors,
          touched,
          isSubmitting
        }) => (
          <Form>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Select
                    fullWidth
                    label='Місто'
                    options={cityOptions}
                    value={values.city}
                    onChange={value => setFieldValue('city', value)}
                    error={Boolean(touched.city && errors.city)}
                    helperText={
                      touched.city && errors.city ? errors.city : undefined
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label='Назва'
                    name='name'
                    variant='standard'
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.name && errors.name)}
                    helperText={
                      touched.name && errors.name ? errors.name : undefined
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label='Ціна в бонусних баллах'
                    name='bonusPointsPrice'
                    type='number'
                    variant='standard'
                    value={values.bonusPointsPrice}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(
                      touched.bonusPointsPrice && errors.bonusPointsPrice
                    )}
                    helperText={
                      touched.bonusPointsPrice && errors.bonusPointsPrice
                        ? errors.bonusPointsPrice
                        : undefined
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <Select
                    fullWidth
                    label='Партнерська компанія'
                    options={partnerCompaniesOptions}
                    value={values.partnerCompany as string}
                    onChange={value => setFieldValue('partnerCompany', value)}
                    error={Boolean(
                      touched.partnerCompany && errors.partnerCompany
                    )}
                    helperText={
                      touched.partnerCompany && errors.partnerCompany
                        ? errors.partnerCompany
                        : undefined
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label='Опис'
                    name='description'
                    variant='standard'
                    multiline
                    rows={3}
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.description && errors.description)}
                    helperText={
                      touched.description && errors.description
                        ? errors.description
                        : undefined
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <FilePickerInput
                    label='Фонове зображення'
                    value={bgPhotoFile || undefined}
                    onChange={(file: File) => {
                      setBgPhotoFile(file);
                      setFieldValue('bgPhoto', URL.createObjectURL(file));
                    }}
                    // previewUrl='https://example.com/image.jpg'
                    // previewStyle={{
                    //   borderRadius: '50%',
                    //   objectFit: 'cover',
                    //   width: 150,
                    //   height: 150
                    // }}
                  />
                  {touched.bgPhoto &&
                    errors.bgPhoto &&
                    typeof errors.bgPhoto === 'string' && (
                      <div style={{ color: 'red', fontSize: '0.8rem' }}>
                        {errors.bgPhoto}
                      </div>
                    )}
                </Grid>
                <Grid item xs={12}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={values.enabled}
                          onChange={e =>
                            setFieldValue('enabled', e.target.checked)
                          }
                        />
                      }
                      label='Активовано'
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12}>
                  <FieldArray name='bonuses'>
                    {({ push, remove }) => (
                      <div>
                        <h3>Бонуси</h3>
                        {values.bonuses.map((bonus, index) => (
                          <Grid
                            container
                            spacing={2}
                            key={index}
                            alignItems='center'
                          >
                            <Grid item xs={3}>
                              <Select
                                label='Тип бонусу'
                                options={[
                                  { label: 'Гроші', value: 'money' },
                                  {
                                    label: 'Підписка',
                                    value: 'subscriptionPromocode'
                                  }
                                ]}
                                value={bonus.type}
                                onChange={value =>
                                  setFieldValue(`bonuses[${index}].type`, value)
                                }
                              />
                            </Grid>
                            {bonus.type === 'money' && (
                              <Grid item xs={3}>
                                <TextField
                                  fullWidth
                                  label='Сума бонусу'
                                  name={`bonuses[${index}].amount`}
                                  type='number'
                                  variant='standard'
                                  value={bonus.amount}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={
                                    touched.bonuses &&
                                    touched.bonuses[index] &&
                                    Boolean(
                                      errors.bonuses &&
                                        errors.bonuses[index] &&
                                        (errors.bonuses[index] as any).amount
                                    )
                                  }
                                  helperText={
                                    touched.bonuses &&
                                    touched.bonuses[index] &&
                                    errors.bonuses &&
                                    errors.bonuses[index] &&
                                    typeof (errors.bonuses[index] as any)
                                      .amount === 'string'
                                      ? (errors.bonuses[index] as any).amount
                                      : ''
                                  }
                                />
                              </Grid>
                            )}
                            {bonus.type === 'subscriptionPromocode' && (
                              <Grid item xs={3}>
                                <Select
                                  fullWidth
                                  label='План підписки'
                                  options={subscriptionPlansOptions}
                                  value={bonus.subscriptionPlan}
                                  onChange={value =>
                                    setFieldValue(
                                      `bonuses[${index}].subscriptionPlan`,
                                      value
                                    )
                                  }
                                  error={Boolean(
                                    touched.bonuses &&
                                      touched.bonuses[index] &&
                                      errors.bonuses &&
                                      errors.bonuses[index] &&
                                      (errors.bonuses[index] as any)
                                        .subscriptionPlan
                                  )}
                                  helperText={
                                    touched.bonuses &&
                                    touched.bonuses[index] &&
                                    errors.bonuses &&
                                    errors.bonuses[index] &&
                                    typeof (errors.bonuses[index] as any)
                                      .subscriptionPlan === 'string'
                                      ? (errors.bonuses[index] as any)
                                          .subscriptionPlan
                                      : ''
                                  }
                                />
                              </Grid>
                            )}
                            <Grid item xs={2}>
                              <TextField
                                fullWidth
                                label='nthSpin'
                                name={`bonuses[${index}].nthSpin`}
                                type='number'
                                variant='standard'
                                value={bonus.nthSpin}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={
                                  touched.bonuses &&
                                  touched.bonuses[index] &&
                                  Boolean(
                                    errors.bonuses &&
                                      errors.bonuses[index] &&
                                      (errors.bonuses[index] as any).nthSpin
                                  )
                                }
                                helperText={
                                  touched.bonuses &&
                                  touched.bonuses[index] &&
                                  errors.bonuses &&
                                  errors.bonuses[index] &&
                                  typeof (errors.bonuses[index] as any)
                                    .nthSpin === 'string'
                                    ? (errors.bonuses[index] as any).nthSpin
                                    : ''
                                }
                              />
                            </Grid>
                            <Grid item xs={1}>
                              {index > 0 && (
                                <IconButton
                                  size='small'
                                  onClick={() => remove(index)}
                                >
                                  <Delete fontSize='small' />
                                </IconButton>
                              )}
                            </Grid>
                          </Grid>
                        ))}
                        <Button
                          variant='outlined'
                          onClick={() =>
                            push({
                              type: 'money',
                              amount: 0,
                              nthSpin: values.bonuses.length + 1
                            })
                          }
                          style={{ marginTop: '10px' }}
                        >
                          Додати бонус
                        </Button>
                      </div>
                    )}
                  </FieldArray>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Скасувати</Button>
              <Button type='submit' color='primary' disabled={isSubmitting}>
                Зберегти
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
});

export default FortuneWheelConfigModal;
