import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  IconButton
} from '@mui/material';

import { Delete } from '@mui/icons-material';

// import { formatDate } from '../../../../resources/functions';
import { RewardPartnerCompany } from '../../../../redux/slices/rewardPartnerCompanySlice';

interface Props {
  items: RewardPartnerCompany[];
  openDialog?: (partnerCompany: RewardPartnerCompany) => () => void;
  onDelete?: (_id: string) => void;
}

const cellStyles = {
  color: 'white'
};

const RewardsPartnersTable = ({ items, openDialog, onDelete }: Props) => {
  return (
    <TableContainer sx={{ mt: 3 }} component={Paper}>
      <Table>
        <TableHead sx={{ bgcolor: '#2FCB75' }}>
          <TableRow>
            <TableCell sx={cellStyles}>Зображення</TableCell>
            <TableCell sx={cellStyles}>Назва</TableCell>
            {/* <TableCell sx={cellStyles}>Дата створення</TableCell>
            <TableCell sx={cellStyles}>Дата оновлення</TableCell> */}
            <TableCell sx={cellStyles}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map(partnerCompany => {
            return (
              <TableRow
                hover
                key={partnerCompany._id}
                onClick={openDialog?.(partnerCompany)}
              >
                <TableCell>
                  {partnerCompany.logo?.startsWith('uploads/') ? (
                    <img
                      alt='icon-img'
                      style={{
                        maxWidth: 60,
                        borderRadius: '50%',
                        objectFit: 'cover',
                        width: 60,
                        height: 60
                      }}
                      src={`${process.env.REACT_APP_API_URL}/public/${partnerCompany.logo}`}
                    />
                  ) : (
                    'Немає логотипу'
                  )}
                </TableCell>
                <TableCell>{partnerCompany.name}</TableCell>
                {/* <TableCell>{formatDate(partnerCompany.createdAt)}</TableCell>
                <TableCell>{formatDate(partnerCompany.updatedAt)}</TableCell> */}
                <TableCell>
                  {/* {isSuperAdmin ? ( */}
                  <>
                    {/* <IconButton
                      size='small'
                      onClick={() => {
                        openDialog?.(partnerCompany);
                      }}
                    >
                      <Edit />
                    </IconButton> */}

                    <IconButton
                      size='small'
                      onClick={() => onDelete?.(partnerCompany._id)}
                    >
                      <Delete fontSize='small' />
                    </IconButton>
                  </>
                  {/* ) : null} */}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default RewardsPartnersTable;
