import React from 'react';
import { Props } from './interface';
import { MenuItem, TextField } from '@mui/material';

const Select = (props: Props) => {
  const {
    options,
    label,
    value,
    onChange,
    style,
    fullWidth,
    margin,
    className,
    disabled,
    error,
    helperText
  } = props;

  return (
    <TextField
      className={className}
      margin={margin}
      fullWidth={fullWidth}
      select
      label={label}
      onChange={event => onChange(event.target.value)}
      value={value}
      style={style}
      disabled={disabled}
      error={error}
      helperText={helperText}
    >
      {options.map(item => (
        <MenuItem key={item.value} value={item.value}>
          {item.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default Select;
