import { Stack, Typography } from '@mui/material';

interface ITableNoItemsProps {
  text?: string;
}
const TableNoItems = ({ text = 'Немає данних' }: ITableNoItemsProps) => {
  return (
    <Stack
      direction='row'
      alignItems='center'
      justifyContent={'center'}
      spacing={3}
      mt={2}
    >
      <Typography>{text}</Typography>
    </Stack>
  );
};

export default TableNoItems;
