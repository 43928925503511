import { createSlice } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../store';
import axios from '../../resources/axios';
import { handleDefaultErrors } from '../../resources/functions';
import { RewardPartnerCompany } from './rewardPartnerCompanySlice';

export interface RewardBonusesData {
  _id?: string;
  name: string;
  enabled?: boolean;
  bonusPointsPrice: number;
  bgPhoto?: string;
  instagram?: string;
  website?: string;
  location?: {
    lat: number;
    lon: number;
  };
  description: string;
  partnerCompany: string | RewardPartnerCompany;
  activateLimit: number;
}

export interface RewardBonuses extends RewardBonusesData {
  _id: string;
  city?: string;
  activateCount?: number;
  createdAt: string;
  updatedAt: string;
}

interface RewardBonusesState {
  items: any[];
  shouldUpdate: boolean;
  limit: number;
  page: number;
  total: number;
  loading: boolean;
}

const initialState: RewardBonusesState = {
  items: [],
  loading: false,
  shouldUpdate: true,
  limit: 20,
  page: 1,
  total: 1
};

const rewardBonusesSlice = createSlice({
  name: 'rewardBonuses',
  initialState,
  reducers: {
    setRewardBonusesLoading: (state, action) => {
      state.loading = action.payload.data;
    },
    getRewardBonuses: (state, action) => {
      state.items = action.payload.data;
    }
  }
});

export const { getRewardBonuses, setRewardBonusesLoading } =
  rewardBonusesSlice.actions;

export const refreshRewardBonusesAsync = (): AppThunk => async dispatch => {
  try {
    const response = await axios.get('/reward/external_reward/admin');
    dispatch(getRewardBonuses(response.data));
  } catch (error) {
    handleDefaultErrors(error);
  }
};

export const getRewardBonusesAsync = (): AppThunk => async dispatch => {
  try {
    dispatch(setRewardBonusesLoading(true));
    dispatch(refreshRewardBonusesAsync());
    dispatch(setRewardBonusesLoading(false));
  } catch (error) {
    handleDefaultErrors(error);
  }
};

export const deleteRewardBonusesAsync =
  (id: string, success?: () => void): AppThunk =>
  async dispatch => {
    try {
      await axios.delete(`/reward/external_reward/${id}`);

      if (success) {
        success();
      }
      dispatch(refreshRewardBonusesAsync());
    } catch (error) {
      handleDefaultErrors(error);
    }
  };

export const updateRewardBonusesAsync =
  (
    data: RewardBonusesData,
    file?: string | File,
    success?: () => void
  ): AppThunk =>
  async dispatch => {
    try {
      let partnerRewardData = data;
      if (file instanceof File) {
        const formData = new FormData();
        formData.append('file', file);
        const response = await axios.post(`/files/persistent`, formData);

        console.log('-=-=- response', response.data);
        partnerRewardData.bgPhoto = response.data.path;
      }

      await axios.put(`/reward/external_reward/${data._id}`, partnerRewardData);

      if (success) {
        success();
      }

      dispatch(refreshRewardBonusesAsync());
    } catch (error) {
      handleDefaultErrors(error);
    }
  };

export const createRewardBonusesAsync =
  (
    data: RewardBonusesData,
    file?: string | File,
    success?: () => void
  ): AppThunk =>
  async dispatch => {
    try {
      let partnerRewardData = data;
      if (file instanceof File) {
        const formData = new FormData();
        formData.append('file', file);
        const response = await axios.post(`/files/persistent`, formData);

        console.log('-=-=- response', response.data);
        partnerRewardData.bgPhoto = response.data.path;
      }

      await axios.post(`/reward/external_reward`, partnerRewardData);

      if (success) {
        success();
      }

      dispatch(refreshRewardBonusesAsync());
    } catch (error) {
      handleDefaultErrors(error);
    }
  };

export const selectRewardBonuses = (state: RootState) => state.rewardBonuses;

export default rewardBonusesSlice.reducer;
