import { useDispatch, useSelector } from 'react-redux';
import RewardBonusesModal, {
  RewardBonusesModalHandles
} from '../RewardBonusesModal';
import { RefObject, useEffect } from 'react';
import {
  createRewardBonusesAsync,
  deleteRewardBonusesAsync,
  getRewardBonusesAsync,
  RewardBonuses as IRewardBonuses,
  RewardBonusesData,
  selectRewardBonuses,
  updateRewardBonusesAsync
} from '../../../../redux/slices/rewardBonusesSlice';
import TableNoItems from '../../TableNoItems';
import RewardBonusesTable from './RewardBonusesTable';

interface IRewardBonusesProps {
  cityId: string;
  modalRef: RefObject<RewardBonusesModalHandles>;
  onSave: (
    values: RewardBonusesData,
    logo?: string | File,
    isEditMode?: boolean
  ) => void;
}

const RewardBonuses = ({ modalRef, onSave }: IRewardBonusesProps) => {
  const dispatch = useDispatch();
  const { items: RewardBonuses, loading } = useSelector(selectRewardBonuses);

  useEffect(() => {
    dispatch(getRewardBonusesAsync());
  }, []);

  console.log('-=-=- RewardBonuses', RewardBonuses);

  const handleSave = (
    data: RewardBonusesData,
    logo?: string | File,
    isEditMode?: boolean
  ) => {
    console.log('-=-=- handleSave', data);
    if (isEditMode) {
      dispatch(
        updateRewardBonusesAsync(data, logo, () => {
          onSave(data, logo, isEditMode);
        })
      );
      return;
    }
    dispatch(
      createRewardBonusesAsync(data, logo, () => {
        onSave(data, logo, isEditMode);
      })
    );
  };

  return (
    <>
      {!loading && !RewardBonuses.length ? <TableNoItems /> : null}

      {!loading && RewardBonuses.length ? (
        <RewardBonusesTable
          items={RewardBonuses}
          openDialog={(partnerCompany: IRewardBonuses) =>
            modalRef.current?.openEditModal(partnerCompany)
          }
          onDelete={(_id: string) => dispatch(deleteRewardBonusesAsync(_id))}
        />
      ) : null}

      <RewardBonusesModal ref={modalRef} onSave={handleSave} />
    </>
  );
};

export default RewardBonuses;
